import React from "react"
import Layout from "../components/Layout"

const NotFoundPage = ({ location }) => {
  const lang = "en"

  // Page setting
  const pageTitle = "NOT FOUND"
  const pageSlug = "notfound"

  return (
    <Layout lang={lang} location={location} pageSlug={pageSlug}>
      <div
        style={{
          padding: "6rem 0",
          textAlign: "center",
          display: "grid",
          placeItems: "center",
          height: "60vh",
        }}
      >
        <div>
          <div className="page-title-wrapper">
            <h1
              className="page-title container-narrow"
              style={{ fontWeight: "100", marginBottom: "4rem" }}
            >
              {pageTitle}
            </h1>
          </div>
          <article className="content-area">
            <div className="container-narrow">
              <p>このページは存在しません。</p>
              <p>You just hit a page that doesn&#39;t exist.</p>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
